import { IoMenu } from "react-icons/io5";

const MobileNav = ({ openAside }) => {
  return (
    <header className="mobile-header">
      <div className="container-full">
        <div className="mobile-header__container">
          <div className="p-left">
            <div className="logo col-4">
              <a href="/">
                <img
                  style={{ width: "100%" }}
                  src="/assets/images/logo/admin-ajax.png"
                  alt="logo"
                />
              </a>
            </div>
          </div>
          <div className="p-right">
            <button onClick={openAside} id="nav-opn-btn">
              <IoMenu />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default MobileNav;
