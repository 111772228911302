import { lazy, Suspense, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Route, Routes } from "react-router-dom";
import PageWrapper from "./pages/PageWrapper";
import PageNotFound from "./pages/PageNotFound";
import PreLoader from "./components/PreLoader";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import WaitList from "./pages/WaitList";
// import "/assets/js/custom.js";

function App() {
  //useEffect
  useEffect(() => {
    AOS.init();
  }, []);
  const HomePage = lazy(() => import("./pages/HomePage"));
  const WaitList = lazy(() => import("./pages/WaitList"));
  return (
    <>
      <Helmet>
        <title>chefsNcooks</title>
        <meta name="theme-color" content="#fecd02" />
        {/* <!-- Meta Tags --> */}
        <meta
          name="description"
          content="Need a chef for your next event? Find & book talented local chefs for hire. Perfect for weddings, parties & private dinners. Fast, easy & affordable."
        />
        <meta
          name="keywords"
          content="chef, cook, booking, hire, event, wedding, party, chefsNcooks, chefs, cooks, resturants, michelin"
        />

        {/* <!-- Open Graph Meta Tags (for social media) --> */}
        <meta property="og:title" content="chefsNcooks" />
        <meta
          property="og:description"
          content="Elevate your next meal with incredible chefs & cooks! Our platform connects you with top culinary talent for any occasion. Book private chefs, cooking classes & more!"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dcbbywfud/image/upload/v1715227466/promotions-bg_rcsypb.png"
        />
        <meta property="og:url" content="http://chefs-n-cooks.com" />

        {/* <!-- Twitter Card Meta Tags (for social media) --> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="chefsNcooks" />
        <meta
          name="twitter:description"
          content="Elevate your next meal with incredible chefs & cooks! Our platform connects you with top culinary talent for any occasion. Book private chefs, cooking classes & more!"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/dcbbywfud/image/upload/v1715227466/promotions-bg_rcsypb.png"
        />

        {/* <!-- Other SEO elements --> */}
        <link rel="canonical" href="https://www.chefsncooks.co" />
        <link rel="alternate" hreflang="en" href="http://chefs-n-cooks.com" />
        <link rel="apple-touch-icon" href="/chefsNcookslogo.png" />
      </Helmet>
      <Routes>
        <Route path="/" element={<PageWrapper />}>
          <Route
            index
            path="/"
            element={
              <Suspense fallback={<PreLoader />}>
                <HomePage />{" "}
              </Suspense>
            }
          />
          <Route
            index
            path="/join-wait-list"
            element={
              <Suspense fallback={<PreLoader />}>
                <WaitList />
              </Suspense>
            }
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
