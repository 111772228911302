import { FaLinkedin } from "react-icons/fa";
import { FaFacebook, FaInstagram, FaTiktok, FaXTwitter } from "react-icons/fa6";
import { TiTimesOutline } from "react-icons/ti";

const AsideNav = ({ close, openModal }) => {
  return (
    <aside id="offcanvas-nav" className={openModal}>
      <nav className="m-nav">
        <button onClick={close} id="nav-cls-btn">
          <TiTimesOutline color="#fff" fontSize={35} />
        </button>
        <div className="logo col-6">
          <a href="/">
            <img
              style={{ width: "100%" }}
              src="/assets/images/logo/admin-ajax.png"
              alt="logo"
            />
          </a>
        </div>
        <a
          style={{
            background: "#f0b830",
            color: "#000929",
            padding: "15px",
            fontWeight: "bold",
            borderRadius: "10px",
          }}
          href="/join-wait-list"
        >
          Join our waitlist
        </a>
        <h2 style={{ color: "#fff", margin: "2rem 0" }}>Connect with us</h2>
        <ul className="nav-links">
          <li className="dropdown">
            <a
              href="https://www.facebook.com/profile.php?id=61550054944056"
              target="_blank"
            >
              <FaFacebook
                color="#f0b830"
                style={{ marginRight: "1rem" }}
                fontSize={30}
              />
              Facebook
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/chefsncooks_/" target="_blank">
              <FaInstagram
                color="#f0b830"
                style={{ marginRight: "1rem" }}
                fontSize={30}
              />
              Instagram
            </a>
          </li>
          <li>
            <a href="https://twitter.com/chefsncooks_" target="_blank">
              <FaXTwitter
                color="#f0b830"
                style={{ marginRight: "1rem" }}
                fontSize={30}
              />
              Twitter
            </a>
          </li>
          <li>
            <a
              href="https://www.tiktok.com/@chefsncooks?_t=8mW9I1GzJa9&_r=1"
              target="_blank"
            >
              <FaTiktok
                color="#f0b830"
                style={{ marginRight: "1rem" }}
                fontSize={30}
              />
              TikTok
            </a>
          </li>
          <li className="dropdown">
            <a href="#">
              <FaLinkedin
                color="#f0b830"
                style={{ marginRight: "1rem" }}
                fontSize={30}
              />
              LinkedIn
            </a>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default AsideNav;
