// import React from 'react'
import axios from "axios";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FaLinkedin, FaTiktok, FaXTwitter } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Footer = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [subscribing, setSubscribing] = useState(false);

  const handleSubcribe = async (e) => {
    e.preventDefault();
    setSubscribing(true);
    try {
      console.log(email, "form");
      const response = await axios.post(
        "https://chefs-n-cook-server.onrender.com/api/v1/subscribe",
        {
          email: email,
        }
      );
      console.log(response);
      setSubscribing(false);
      if (response.status === 201) {
        toast("🦄 Wow you've subscribed successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        // setSuccessful(true);
      }
      // Handle the response as needed
    } catch (error) {
      console.error("There was an error!", error);
      setSubscribing(false);
    }
  };
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12" data-aos="fade-right">
              <div className="footer-logo">
                <div className="logo col-6">
                  <img
                    style={{ width: "100%" }}
                    src="/assets/images/logo/admin-ajax.png"
                    alt="logo"
                  />
                </div>
              </div>
              <div className="footer-text">
                <h4>
                  At chefsNcooks, we invite you to embark on a journey of taste
                  and delight.Our tables are designed to satisfy your taste.
                </h4>
              </div>
              <div className="footer-text">
                <h4 style={{ color: "#737373" }}>
                  For more inquiries, send us an email to contact us at{" "}
                  <a
                    style={{
                      background: "#737373",
                      color: "var(--primary-color)",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                    href="mailto:info@chefsncooks.co"
                  >
                    info@chefsncooks.co
                  </a>
                </h4>
              </div>
              <div className="footer-text">
                <h4 style={{ color: "#737373" }}>
                  or call {" - "}
                  <a
                    style={{
                      background: "#737373",
                      color: "var(--primary-color)",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                    href="tel:+1 587 328 0819"
                  >
                    {" "}
                    +1 587 328 0819
                  </a>
                </h4>
              </div>
            </div>
            <div className="col-lg-8 mol-md-12">
              <div className="row">
                <div
                  className="col-lg-3 col-md-3"
                  data-aos="fade-right"
                  data-aos-delay={100}
                >
                  <div className="quick-line-item">
                    <div className="quick-line-item-head">
                      <h3>Quick Link</h3>
                    </div>
                    <div className="quick-line-menu">
                      <ul>
                        <li>
                          <a href="/join-wait-list">Wait List</a>
                        </li>
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="#">FAQ</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-3"
                  data-aos="fade-right"
                  data-aos-delay={200}
                >
                  <div className="quick-line-item">
                    <div className="quick-line-item-head">
                      <h3>Terms &amp; Privacy</h3>
                    </div>
                    <div className="quick-line-menu">
                      <ul>
                        <li>
                          <a href="trust-safety.html">Trust &amp; Safety</a>
                        </li>
                        <li>
                          <a href="terms-service.html">Terms of Service</a>
                        </li>
                        <li>
                          <a href="privacy-policy.html">Privacy Policy</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6"
                  data-aos="fade-right"
                  data-aos-delay={300}
                >
                  <div
                    id="getWaitlistContainer"
                    data-waitlist_id="17117"
                    data-widget_type="WIDGET_2"
                  ></div>
                  <div className="quick-line-item">
                    <div className="quick-line-item-head">
                      <h3>Subscribe our Newsletter</h3>
                    </div>

                    <div className="quick-line-btn">
                      <div className="icon">
                        <img src="assets/images/icon/sms.png" alt="icon" />
                      </div>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleFormControlInput6"
                        placeholder="Your email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <button
                        onClick={handleSubcribe}
                        className="main-btn-four"
                      >
                        {subscribing ? "..." : "Subscribe"}
                      </button>
                    </div>
                    <div className="quick-line-btn-text">
                      <h6>
                        Stay connected with us through our social media
                        channels:
                      </h6>
                    </div>
                    <div className="quick-line-btn-img">
                      <a
                        href="https://www.facebook.com/profile.php?id=61550054944056"
                        target="_blank"
                      >
                        <FaFacebook color="#f0b830" fontSize={30} />
                      </a>
                      <a
                        href="https://www.instagram.com/chefsncooks_/"
                        target="_blank"
                      >
                        <FaInstagram color="#f0b830" fontSize={30} />
                      </a>
                      <a
                        href="https://twitter.com/chefsncooks_"
                        target="_blank"
                      >
                        <FaXTwitter color="#f0b830" fontSize={30} />
                      </a>
                      <a
                        href="https://www.tiktok.com/@chefsncooks?_t=8mW9I1GzJa9&_r=1"
                        target="_blank"
                      >
                        <FaTiktok color="#f0b830" fontSize={30} />
                      </a>
                      <a href="#">
                        <FaLinkedin color="#f0b830" fontSize={30} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="quick-line-btn-text">
            <p style={{ color: "#fff" }}>
              {" "}
              © 2024 chefsNcooks. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
