import React from "react";
import { ThreeDots } from "react-loader-spinner";
// import "./PreLoader.css";
const PreLoader = () => {
  return (
    <section
      style={{
        background: "white",
        height: "100vh",
        position: "fixed",
        top: "0",
        width: "100%",
        zIndex: 2000,
      }}
      className="py-3 fixed py-md-5 min-vh-100 d-flex justify-content-center align-items-center"
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              className="text-center"
            >
              <ThreeDots
                visible={true}
                height="80"
                width="80"
                color="#fecd02"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ margin: "auto" }}
                wrapperClass=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PreLoader;
