// import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import MobileNav from "../components/MobileNav";
import AsideNav from "../components/AsideNav";
import { Outlet } from "react-router-dom";
import { useState } from "react";
import { Helmet } from "react-helmet";

const PageWrapper = () => {
  const [asideClass, setAsideClass] = useState("");
  const openAside = () => {
    setAsideClass("open");
  };
  const closeAside = () => {
    setAsideClass("");
  };

  return (
    <div>
      <Helmet>
        <title>chefsNcooks</title>
        <meta name="theme-color" content="#fecd02" />
        {/* <!-- Meta Tags --> */}
        <meta
          name="description"
          content="Need a chef for your next event? Find & book talented local chefs for hire. Perfect for weddings, parties & private dinners. Fast, easy & affordable."
        />
        <meta
          name="keywords"
          content="chef, cook, booking, hire, event, wedding, party, chefsNcooks, chefs, cooks, resturants, michelin"
        />

        {/* <!-- Open Graph Meta Tags (for social media) --> */}
        <meta property="og:title" content="chefsNcooks" />
        <meta
          property="og:description"
          content="Elevate your next meal with incredible chefs & cooks! Our platform connects you with top culinary talent for any occasion. Book private chefs, cooking classes & more!"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dcbbywfud/image/upload/v1715227466/promotions-bg_rcsypb.png"
        />
        <meta property="og:url" content="http://chefs-n-cooks.com" />

        {/* <!-- Twitter Card Meta Tags (for social media) --> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="chefsNcooks" />
        <meta
          name="twitter:description"
          content="Elevate your next meal with incredible chefs & cooks! Our platform connects you with top culinary talent for any occasion. Book private chefs, cooking classes & more!"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/dcbbywfud/image/upload/v1715227466/promotions-bg_rcsypb.png"
        />

        {/* <!-- Other SEO elements --> */}
        <link rel="canonical" href="https://www.chefs-n-cooks.com" />
        <link rel="alternate" hreflang="en" href="http://chefs-n-cooks.com" />
        <link rel="apple-touch-icon" href="/chefsNcookslogo.png" />
        <script
          src="https://getlaunchlist.com/js/widget-diy.js"
          type="text/javascript"
          defer
        />
      </Helmet>
      <Navbar />
      <MobileNav openAside={openAside} />
      <AsideNav close={closeAside} openModal={asideClass} />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default PageWrapper;
